import React from 'react';
import './Footer.css';
// import logo from '../assets/footer_files/Logo-Sqr.png';
import { Link } from 'react-router-dom';
const fb = require('../assets/fb_logo.png')
const ig = require('../assets/ig_logo.png')


const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-location">
          <h4>Reservations Office</h4>
          <p>0511 Purok 2 Makiling Calamba City, Laguna</p>
          <p>0968-679-3182</p>
          <p>HapiFacebeautycenter@gmail.com</p>

        </div>
        <div className="footer-schedule">
          <h4>Office Hours</h4>
          <p>Monday to Saturday</p>
          <p>2:00 PM to 8:00 PM</p>
          <p>Sunday</p>
          <p>10:00 AM to 07:00 PM</p>
        </div>
        <div className="footer-socials">
          <h4>Socials</h4>
          <div className="social-links">
            <Link to='https://www.facebook.com/profile.php?id=100094273285056' target="_blank" rel="noopener noreferrer">
              <img src={fb} alt="Facebook Link" className="social-link-btn"></img>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 HapiFace Wellnes & Beauty. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
