// src/App.tsx
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

// Lazy load your page components
const Home = lazy(() => import('./pages/Home'));

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        {/* <Navbar /> */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Add more routes as needed */}
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
